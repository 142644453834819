// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-index-tsx": () => import("/Users/jayant.bhawal/Projects/jayant/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-404-tsx": () => import("/Users/jayant.bhawal/Projects/jayant/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-experiments-animated-characters-tsx": () => import("/Users/jayant.bhawal/Projects/jayant/src/pages/experiments/animated-characters.tsx" /* webpackChunkName: "component---src-pages-experiments-animated-characters-tsx" */),
  "component---src-pages-experiments-animation-comparisons-tsx": () => import("/Users/jayant.bhawal/Projects/jayant/src/pages/experiments/animation-comparisons.tsx" /* webpackChunkName: "component---src-pages-experiments-animation-comparisons-tsx" */),
  "component---src-pages-experiments-canvas-draw-tsx": () => import("/Users/jayant.bhawal/Projects/jayant/src/pages/experiments/canvas-draw.tsx" /* webpackChunkName: "component---src-pages-experiments-canvas-draw-tsx" */),
  "component---src-pages-experiments-cards-tsx": () => import("/Users/jayant.bhawal/Projects/jayant/src/pages/experiments/cards.tsx" /* webpackChunkName: "component---src-pages-experiments-cards-tsx" */),
  "component---src-pages-experiments-clip-path-route-tsx": () => import("/Users/jayant.bhawal/Projects/jayant/src/pages/experiments/clip-path-route.tsx" /* webpackChunkName: "component---src-pages-experiments-clip-path-route-tsx" */),
  "component---src-pages-experiments-flip-animation-technique-tsx": () => import("/Users/jayant.bhawal/Projects/jayant/src/pages/experiments/flip-animation-technique.tsx" /* webpackChunkName: "component---src-pages-experiments-flip-animation-technique-tsx" */),
  "component---src-pages-experiments-redux-connections-tsx": () => import("/Users/jayant.bhawal/Projects/jayant/src/pages/experiments/redux-connections.tsx" /* webpackChunkName: "component---src-pages-experiments-redux-connections-tsx" */),
  "component---src-pages-experiments-shared-element-transitions-tsx": () => import("/Users/jayant.bhawal/Projects/jayant/src/pages/experiments/shared-element-transitions.tsx" /* webpackChunkName: "component---src-pages-experiments-shared-element-transitions-tsx" */),
  "component---src-pages-experiments-top-facing-cube-tsx": () => import("/Users/jayant.bhawal/Projects/jayant/src/pages/experiments/top-facing-cube.tsx" /* webpackChunkName: "component---src-pages-experiments-top-facing-cube-tsx" */)
}

